a {
    text-decoration: none;
}

.button {
    font-weight: 700;
    color: #000;
    background-color: #fff;
    padding: 14px 16px;
    font-size: 18px;
    border-radius: 0;
    position: relative;
    transition: 0.3s ease-in-out;

    span {
        position: relative;
        color: #000;
    }

    &::before {
        content: "";
        background: #121212;
        width: 0;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        transition: 0.3s ease-in-out;
    }

    &:hover {
        span {
            color: #fff;
        }

        &::before {
            width: 100%;
        }
    }
}